<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="title">
            <template v-slot:label>
              Tiêu đề <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tiêu đề"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="initData.name"
                name="title"
                placeholder="Title bài viết"
                :state="getElementState(errors)"
                aria-describedby="input-1-live-feedback"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="header">
            <template v-slot:label>
              Header bài viết <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Header bài viết"
              rules="required"
            >
              <b-form-textarea
                id="header"
                v-model="initData.header"
                name="header"
                placeholder="Header bài viết"
                rows="3"
                max-rows="6"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-img style="height: 200px; margin-bottom: 15px" thumbnail fluid :src="ulrAvatar" alt="Ảnh đại diện"></b-img>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Ảnh đại diện"
            label-for="image"
          >
            <input
              ref="inputFile"
              type="file"
              @change="uploadFile"
              accept="image/*"
              :placeholder="placeholder"
            >
          </b-form-group>

        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Chuyên mục"
            label-for="category"
          >
            <v-select
              id="category"
              v-model="initData.categoryId"
              label="label"
              :options="optCategory"
              :reduce="option => option.value"
              @input="changeCategory"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Tin nổi bật" v-slot="{ ariaDescribedby }">
            <b-form-checkbox
              id="checkbox-1"
              v-model="initData.highlight"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Nội dung bài viết: "
            label-for="description"
          >
            <ckeditor
              v-model="initData.description"
              :config="editorConfig"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Số thứ tự"
            label-for="status"
          >
            <b-form-input
              id="order_no"
              v-model="initData.orderNo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Trạng thái"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="initData.status"
              :options="optionsStatus"
              :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FormArticles',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BImg,
  },
  props: {
    dataSendForm: {
      type: Object,
      default: () => {},
    },

    modalShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      optionsStatus: [],
      editorData: '<p></p>',
      editorConfig: {

      },
      optCategory: [],
      initData: {
        name: '',
        header: '',
        description: '',
        highlight: '',
        id: '',
        status: 1,
        orderNo: 1,
        categoryId: null,
        mediaId: '',
      },
      required,
      placeholder: 'Chọn file để tải tài liệu...',
      ulrAvatar: '',
    }
  },
  computed: {
    ...mapGetters({
      maxOrderNo: 'portalArticle/maxOrderNo',
      articleCategories: 'dropdown/articleCategories',
    }),
  },
  async created() {
    if (this.dataSendForm.id) {
      this.initData = { ...this.dataSendForm }
    }
    // await this.getArticleCategories()

    this.optCategory = this.articleCategories.map(item => ({ value: item.value, label: item.label }))
    if (!this.dataSendForm.categoryId) {
      this.initData.categoryId = this.optCategory[0].value
    }

    if (!this.dataSendForm.id && this.initData.categoryId) {
      await this.getMaxOrderNo(this.initData.categoryId)
      this.initData.orderNo = this.maxOrderNo + 1
    }
    if (this.initData.mediaId) {
      const resLink = await this.getLinkFile(this.initData.mediaId)
      if (resLink.isSuccessful) {
        this.ulrAvatar = resLink.data
      }
    }

    this.optionsStatus = STATUSES.map(item => ({ value: item.value, label: item.label }))
  },
  methods: {
    // handle even
    ...mapActions({
      getMaxOrderNo: 'portalArticle/getMaxOrderNo',
      getArticleCategories: 'dropdown/getArticleCategories',
      hdUploadFile: 'portalArticle/uploadFile',
      getLinkFile: 'portalArticle/getLinkFile',
    }),

    async changeCategory(id) {
      await this.getMaxOrderNo(id)
      this.initData.orderNo = this.maxOrderNo + 1
    },

    async uploadFile(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      files.forEach(file => formData.append('files', file))
      const params = {
        id: this.dataSendForm.id,
        params: formData,
      }
      const response = await this.hdUploadFile(params)
      if (response.isSuccessful) {
        this.initData.mediaId = response.data[0]
        this.showToast('Cập nhật avatar thành công', 'CheckIcon', 'success')
        const resLink = await this.getLinkFile(response.data[0])
        if (resLink.isSuccessful) {
          this.ulrAvatar = resLink.data
        }
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      // this.$refs.inputFile.reset()
      this.$refs.inputFile.value = null
    },

    handleSaveData(e) {
      e.preventDefault()
      this.$emit('saveData', this.initData)
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
