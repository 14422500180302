var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tiêu đề "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tiêu đề","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","name":"title","placeholder":"Title bài viết","state":_vm.getElementState(errors),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.initData.name),callback:function ($$v) {_vm.$set(_vm.initData, "name", $$v)},expression:"initData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"header"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Header bài viết "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Header bài viết","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"header","name":"header","placeholder":"Header bài viết","rows":"3","max-rows":"6","state":_vm.getElementState(errors)},model:{value:(_vm.initData.header),callback:function ($$v) {_vm.$set(_vm.initData, "header", $$v)},expression:"initData.header"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-img',{staticStyle:{"height":"200px","margin-bottom":"15px"},attrs:{"thumbnail":"","fluid":"","src":_vm.ulrAvatar,"alt":"Ảnh đại diện"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ảnh đại diện","label-for":"image"}},[_c('input',{ref:"inputFile",attrs:{"type":"file","accept":"image/*","placeholder":_vm.placeholder},on:{"change":_vm.uploadFile}})])],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Chuyên mục","label-for":"category"}},[_c('v-select',{attrs:{"id":"category","label":"label","options":_vm.optCategory,"reduce":function (option) { return option.value; }},on:{"input":_vm.changeCategory},model:{value:(_vm.initData.categoryId),callback:function ($$v) {_vm.$set(_vm.initData, "categoryId", $$v)},expression:"initData.categoryId"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Tin nổi bật"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"1","unchecked-value":"0"},model:{value:(_vm.initData.highlight),callback:function ($$v) {_vm.$set(_vm.initData, "highlight", $$v)},expression:"initData.highlight"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nội dung bài viết: ","label-for":"description"}},[_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.initData.description),callback:function ($$v) {_vm.$set(_vm.initData, "description", $$v)},expression:"initData.description"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Số thứ tự","label-for":"status"}},[_c('b-form-input',{attrs:{"id":"order_no","type":"number"},model:{value:(_vm.initData.orderNo),callback:function ($$v) {_vm.$set(_vm.initData, "orderNo", $$v)},expression:"initData.orderNo"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Trạng thái","label-for":"status"}},[_c('v-select',{attrs:{"id":"status","options":_vm.optionsStatus,"reduce":function (option) { return option.value; }},model:{value:(_vm.initData.status),callback:function ($$v) {_vm.$set(_vm.initData, "status", $$v)},expression:"initData.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.handleSaveData}},[_vm._v(" Lưu ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }